import React from "react"

import Loadable from "react-loadable-visibility/react-loadable"
import Loading from "../../components/Otros/Loading/Loading"

const LoadableComponent = React.memo(
  Loadable({
    loader: () => import("../../prepages/servicios/SistemaRefrigeracion"),
    loading: Loading,
  })
)

const SistemaRefrigeracion = () => {
  return <LoadableComponent />
}

export default React.memo(SistemaRefrigeracion)
